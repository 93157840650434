<template>
  <v-scale-transition>
    <panel
      :breadcrumbs="breadcrumbs"
      :title="title"
    >
      <v-toolbar
        extension-height="10"
        color="grey darken-4"
        dark
      >
        <v-toolbar-title>Master Dealer States & Districts</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :expand="expand"
        item-key="state"
        class="elevation-1"
        hide-actions
      >
        <template #items="props">
          <tr
            :active="props.selected"
            @click="toggleSelection(props)"
          >
            <td class="text-xs-center">
              {{ props.item.state }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.daily }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.monthly }}
            </td>
            <td class="text-xs-center info">
              {{ props.item.total }}
            </td>
          </tr>
        </template>
        <template #expand="props">
          <v-layout column>
            <v-flex xs13>
              <v-card-text color="darken-5">
                <v-data-table
                  :headers="detailHeaders"
                  :items="props.item.detail"
                  item-key="district"
                  class="elevation-1"
                  disable-initial-sort
                >
                  <template #items="thisProps">
                    <tr>
                      <td class="text-xs-center">
                        {{ thisProps.item.districtD }}
                      </td>
                      <td class="text-xs-center">
                        {{ thisProps.item.dealerD }}
                      </td>
                      <td class="text-xs-center">
                        {{ thisProps.item.dailyD }}
                      </td>
                      <td class="text-xs-center">
                        {{ thisProps.item.monthlyD }}
                      </td>
                      <td class="text-xs-center">
                        {{ thisProps.item.totalD }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-flex>
          </v-layout>
        </template>
        <template slot="footer">
          <tr>
            <td class="text-xs-right">
              Total:
            </td>
            <td class="text-xs-center info">
              {{ totalDealer }}
            </td>
            <td class="text-xs-center info">
              {{ totalDaily }}
            </td>
            <td class="text-xs-center info">
              {{ totalMonthly }}
            </td>
            <td class="text-xs-center info">
              {{ totalActivation }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </panel>
  </v-scale-transition>
</template>

<script>
import { createGetParams } from '@/rest'

const title = 'States & Districts'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
      ],
      items: [],
      title: title,
      expand: false,
      loading: false,
      headers: [
        {
          text: 'State',
          align: 'center',
          sortable: true,
          value: 'state',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Daily',
          align: 'center',
          sortable: true,
          value: 'daily',
        },
        {
          text: 'Monthly',
          align: 'center',
          sortable: true,
          value: 'monthly',
        },
        {
          text: 'Total Activation',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      detailHeaders: [
        {
          text: 'District',
          align: 'center',
          sortable: true,
          value: 'district',
        },
        {
          text: 'Dealer',
          align: 'center',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Daily',
          align: 'center',
          sortable: true,
          value: 'daily',
        },
        {
          text: 'Monthly ',
          align: 'center',
          sortable: true,
          value: 'monthly',
        },
        {
          text: 'Total Activation ',
          align: 'center',
          sortable: true,
          value: 'total',
        },
      ],
      totalDealer: 0,
      totalDaily: 0,
      totalMonthly: 0,
      totalActivation: 0,
      totalDealerDis: 0,
      totalDailyDis: 0,
      totalMonthlyDis: 0,
      totalActivationDis: 0,
      selected: [],
    }
  },
  created: function () {
    this.getActivationsByState(this.state)
  },
  methods: {
    getActivationsByState: async function () {
      const params = createGetParams({ })
      try {
        const response = await this.$rest.get('getMdState.php', params)
        this.items = response.data.item
        this.totalDealer = response.data.totalDealer
        this.totalDaily = response.data.totalDaily
        this.totalMonthly = response.data.totalMonthly
        this.totalActivation = response.data.totalActivation
      } catch (error) {
        console.log(error)
      }
    },

    getAdCity: function (state) {
      const param = { state }
      const callback = (element) => element.state === state
      const index = this.items.findIndex(callback)
      this.items[index].detail.length < 1 && this.$rest.get('getMdCity.php', createGetParams(param))
        .then(function (response) {
          this.items[index].detail = response.data.item
          this.totalDealerDis = response.data.totalDealerDis
          this.totalDailyDis = response.data.totalDailyDis
          this.totalMonthlyDis = response.data.totalMonthlyDis
          this.totalActivationDis = response.data.totalActivationDis
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          console.log(message)
        })
    },

    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getAdCity(item.item.state)
      }
    },
  },
}

</script>
